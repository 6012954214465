import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Testimonials from "../components/Testimonials";
import FullWidthImage from "../components/FullWidthImage";
import HeaderBanner1 from "../components/HeaderBanner1";
import Content4 from "../components/Content4";
import About3 from "../components/About3";
import DotMatrix from "../components/DotMatrix";
import Content2 from "../components/Content2";

// eslint-disable-next-line
export const TeamPageTemplate = ({
  image,
  title,
  main,
  testimonials,
  fullImage,
}) => {
  const heroImage = getImage(image) || image;
  const fullWidthImage = getImage(fullImage) || fullImage;

  return (
    <div className='content'>
      <FullWidthImage img={heroImage} title={title} imgPosition={""} />
      <HeaderBanner1 />
      <section className='w-9/12 mx-auto my-16'>
        <Content2 />
        <div className='pb-6 -mt-2'>
          <DotMatrix />
        </div>
        <div className='bg-opacity-50 p-8 rounded shadow-3xl mt-4'>
          <About3 />
        </div>
        <div className='pb-6 pt-8'>
          <DotMatrix />
        </div>
        <Content4 />
        <div className='pt-6'>
          <DotMatrix />
        </div>
        <div className='pt-4'>
          <Testimonials testimonials={testimonials} />
        </div>
      </section>
      <FullWidthImage
        img={fullWidthImage}
        imgPosition={""}
        className='bottom-fullsize-image h-64'
      />
      <HeaderBanner1 />
    </div>
  );
};

TeamPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const TeamPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout className='font-sans'>
      <TeamPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TeamPage;

export const teamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        main {
          heading
          description
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
