import React from "react";

const Content4 = () => {
  return (
    <div className='px-6 py-8 mx-auto space-y-12 bg-bristol-blue-1 bg-opacity-30 rounded-lg shadow-3xl'>
      <div className='w-full mx-auto space-y-4 text-center'>
        <p className='text-xs font-semibold tracking-wider uppercase'>
          #BristolPlace
        </p>
        <h1 className='text-4xl font-bold leading-tight md:text-5xl'>
        There’s No Place Like Home
        </h1>
      </div>
      <div className='px-4'>
        <div className='pt-12 border-t'>
          <div className='flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row'>
            <img
              src={'img/1-new.jpg'}
              alt=''
              className='self-center flex-shrink-0 w-24 h-24 border rounded-full md:justify-self-start'
            />
            <div className='flex flex-col text-xl'>
              <p className='mb-4'>
              Our mission is to provide quality case management services by focusing on each individual’s skills, strengths and abilities. We also take into consideration their natural supports, routines and rituals, hopes, dreams and aspirations and what they like to do for fun. We are committed to being accessible, responsive and person centered which allows individuals to choose their own caregivers, their supports, as well as their housing and employment opportunities or preferences.
              </p>
              <p className='mb-4'>
              Our vision is to empower people to reach those goals and live their lives to the fullest.
              </p>
              <p className='mb-4'>
              We value each individual for who they are and meet them where they are at in their recovery process. We embrace diversity and work hard to learn and grow from new experiences, beliefs and ideas. We are committed to providing a safe, non-judgmental environment where people feel comfortable, supported and a part of their progression. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content4;
