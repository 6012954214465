import React from "react";
import { FaHandsHelping, FaUniversalAccess } from "react-icons/fa";
import { GiHeartShield } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";


export default function About3() {
  return (
    <div className='px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:px-8'>
      <div className='flex flex-col-reverse md:items-stretch items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <h1 className='font-sans md:text-5xl text-3xl font-bold text-center '>
            <span className='relative lg:whitespace-nowrap'>Who We Are</span>
          </h1>
          <hr className='w-full my-8 border-black' />
          <div className='mt-7 flex flex-col items-center'>
            <a
              href='/'
              aria-label='View'
              className='inline-block mb-5 rounded-full sm:mx-auto'
            >
              <div className='w-20 h-20 bg-bristol-blue-2 shadow rounded-full flex items-center justify-center'>
                <FaUniversalAccess className='fill-icon-blue h-8 text-6xl' />
              </div>
            </a>
            <h2 className='mb-4 font-sans text-3xl tracking-tight text-center sm:text-4xl sm:leading-none'>
              Passionate People
            </h2>
            <p className='text-xl leading-6  text-center w-full'>
              Bristol Place case managers help individuals live life on their terms. With a friendly and caring staff, we provide the
              support and resources needed to maintain independence and
              dignity. Helping people live life on their terms is our mission.
            </p>
          </div>
          <div className='mt-7 flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-bristol-blue-2 mb-4 shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='money'
            >
              <GiHeartShield className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h2 className='mb-4 font-sans text-3xl tracking-tight text-center sm:text-4xl sm:leading-none'>
              Trustworthy Care Providers
            </h2>
            <p className='text-xl leading-6 text-center w-full'>
              We work hard to make sure our case managers have the training and
              experience needed to provide the best services possible. With over
              40 years of experience in human services, we're committed to
              providing excellent service to each person with integrity and
              respect. Bristol Place Corporation solely provides Case Management
              services so we are able to focus all of our time and energy on how
              to best provide these services to each individual.
            </p>
          </div>
          <div className='mt-7 flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-bristol-blue-2 mb-4 shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='phone'
            >
              <HiUserGroup className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h2 className='mb-4 font-sans text-3xl tracking-tight text-center sm:text-4xl sm:leading-none'>
              Our Team
            </h2>
            <p className=' text-xl leading-6 text-center w-full'>
              Our team of professional experienced case managers work tirelessly
              to provide solutions to individuals in need. With our extensive
              knowledge of community resources and coordination with other
              community providers, we are able to offer quality case management
              to the individuals we serve.
            </p>
          </div>
          <div className='mt-7 flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-bristol-blue-2 mb-4 shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='ideas'
            >
              <FaHandsHelping className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h2 className='mb-4 font-sans text-3xl text-center tracking-tight sm:text-4xl sm:leading-none'>
              An Extra Hand
            </h2>
            <p className='text-xl leading-6 text-center w-full'>
              We provide exceptional customer service from the moment we reach out to each person served and set up the first in-home face-to-face visit with them.  We assist each individual in planning for their future and accessing the support they need so they may remain living in their community. We step right in and help individuals obtain the services needed so they can spend more time doing what they enjoy most and focusing on what is important to them.            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
