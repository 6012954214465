import * as React from "react";

const Content2 = () => {
  return (
    <>
       <div className="md:grid grid-cols-12 grid-rows-1 mx-auto mb-20">
     <div className="z-10 row-start-1 col-start-8 col-end-13 lg:ml-6 lg:pl-8 h-full w-[100%] md:w-[115%]">
         <img className="rounded-t-lg md:-mt-4 lg:rounded shadow-3xl" src={"/img/10-new.jpg"} alt="" />
       </div>
       <div className="flex flex-row row-start-1 col-start-1 col-end-11 self-center p-8 rounded-b-lg lg:rounded shadow-3xl">
         
       <div className="md:basis-8/12">
           <h1 className="text-2xl font-bold mt-0 pb-4 text-center">Bristol Place Culture</h1>
           <p className="text-base md:text-xl mb-2 !leading-6">  At Bristol Place, we take our responsibilities seriously and work
              hard to support the people we serve. We also believe you can
              do great work while having a great time. Our company culture is a
              reflection of that commitment to working hard in an inclusive,
              supportive atmosphere. By celebrating our diversity and
              acknowledging our individual contributions, we’ve created a
              welcoming environment where everyone can feel safe to be their
              authentic self. We are advocates not just for the individuals we serve, but
              for each other as well, championing our successes and recognizing
              our achievements. Our team has become a family and we prioritize
              respect and compassion in everything we do.
           </p>
         </div>
         <div className="hidden lg:block lg:basis-4/12"></div>
        
       </div>
     </div>
   </>
  );
};

export default Content2;
