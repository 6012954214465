import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const Testimonials = ({ testimonials }) => (
  <>
    {testimonials.map((testimonial) => (
      <article key={v4()} className="mx-auto shadow-3xl rounded-2xl">
        <section className="relative p-6 overflow-hidden bg-bristol-olive rounded-2xl">
          <div className="lg:mt-6">
            <svg
              className="absolute top-4 left-12 hidden w-12 h-12 -mt-3 -ml-8 text-black opacity-50 xl:block hover:animate-fade-in-down"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
            >
              <path
                strokeWidth={3}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <div className="relative xl:pl-8 lg:flex lg:items-center">
              <div className="relative">
                <blockquote className="relative">
                  <div className="font-serif text-md font-medium leading-9 md:text-xl">
                    <p>{testimonial.quote}</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="object-cover w-12 h-12 mr-4 rounded-full"
                          src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&faces=1&faceindex=1&facepad=2.5&w=500&h=500&q=80"
                          alt=""
                        />{" "}
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium leading-6 text-white">
                          <cite> – {testimonial.author}</cite>
                        </div>
                      </div>
                    </div>
                  </div>
                  <svg
                    className="absolute bottom-5 right-0 hidden w-12 h-12 -mt-3 -ml-8 text-black opacity-50 xl:block"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 144 144"
                  >
                    <path
                      strokeWidth={3}
                      d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                    />
                  </svg>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </article>
    ))}
  </>
);

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;
